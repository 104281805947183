<main>
  <h5 class="section-title">Características clínicas</h5>
  <section>
    <div class="container-fields">
      <field-validate
        [ngStyle]="
          stagesDisease?.length ? { width: '232px' } : { width: '313px' }
        "
        [ngClass]="customCss"
        fieldName="Finalidade da orientação"
        [showSuccessBackground]="false"
        [loading]="loadingPurposes"
      >
        <select
          [disabled]="!dataList"
          [required]="true"
          [(ngModel)]="goalOrientation"
          (ngModelChange)="handlePurposeSelected()"
        >
          <option disabled [ngValue]="null" selected>Selecione</option>
          @for (item of dataList; track item.id;) {
          <option id="option" [ngValue]="item">{{ item.name }}</option>
          }
        </select>
      </field-validate>

     @if (stagesDisease?.length && goalOrientation) {
      <div
        class="activity-field"
        [ngStyle]="
          stagesDisease?.length ? { width: '232px' } : { width: '313px' }
        "
      >
        <app-pet-stages-disease
          [label]="stageDiseaseLabel"
          [showStagesDisease]="stagesDisease != null"
          [stagesDiseases]="stagesDisease"
          (stageDiseaseSelected)="handleStageSelected($event)"
        />
      </div>

      } @if (physicalActivities && goalOrientation) {
      <div
        class="activity-field"
        [ngStyle]="
          stagesDisease?.length ? { width: '232px' } : { width: '313px' }
        "
      >
        <app-pet-activity-level
          [label]="physicalActivitiesLabel"
          [showPhysicalActivities]="physicalActivities != null"
          [physicalActivities]="physicalActivities"
          [goalOrientationSelected]="goalOrientation.id"
          [speciePet]="specieSelected"
          [selectedActivityId]="nutritionalGuidanceForm.controls.nivelAtividade.value"
          [activityIdSelected]="activityLevel"
          (activitySelected)="handleActivitySelected($event)"
        >
        </app-pet-activity-level>
      </div>

      } @if(goalOrientation) {
      <field-validate
        [ngStyle]="
          stagesDisease?.length ? { width: '232px' } : { width: '313px' }
        "
        fieldName="Peso atual (kg)"
        [showSuccessBackground]="false"
      >
        <input
          [(ngModel)]="weightPet"
          id="weight"
          name="weight"
          maxlength="6"
          required
          type="tel"
          placeholder="00,000"
          autocomplete="off"
          (change)="setWeight($event)"
        />
      </field-validate>
      }
    </div>
  </section>
</main>

@if (showScore && goalOrientation) {
<app-score
  [specieSelected]="specieSelected"
  [weight]="weightPet"
  [orientationPurpose]="goalOrientation"
  [selectedActivityId]="nutritionalGuidanceForm.controls.nivelAtividade.value"
  (scoreSelectedEmitter)="handleScoreSelected($event)"
  [obesityTreatment]="obesityTreatment"
></app-score>
}
