<div [ngClass]="mobileScreen?'credentials-container-mobile':'credentials-container'">
  <div class="logo-mobile" *ngIf="mobileScreen">
    <button class="button-back-mobile" *ngIf="mobileScreen && !hideMobileBackButton" (click)="getNavigate()"><i
        class="bi bi-arrow-left-short"></i></button>
    <img src="../../../../../assets/images/logo-login-mobile.webp">
  </div>
  <section class="form">
    <div class="logo" *ngIf="!mobileScreen">
      <img [src]="pathImg" alt="logo-premier" (click)="getNavigate()">
    </div>
    <ng-content></ng-content>
  </section>
</div>
