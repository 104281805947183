<app-background urlNavigation="/login" (backEmitter)="onBack()">
  <div class="form-container" *ngIf="!registerSuccessfully">
    <form class="form" [formGroup]="registrationForm">
      <h1 class="form-title">Faça seu cadastro</h1>
      <div>
        <field-validate fieldName="Nome Completo">
          <input
            type="text"
            id="name"
            onlyText
            formControlName="name"
            placeholder="Informe o seu nome"
            maxlength="80"
            (change)="setFormStorage('nome')"
            [nextField]="email"
          />
        </field-validate>
      </div>
      <div>
        <field-validate fieldName="E-mail">
          <input
            #email
            type="text"
            id="email"
            formControlName="email"
            placeholder="email@premiervet.com.br"
            (change)="setFormStorage('email')"
            [nextField]="cpf"/>
        </field-validate>
      </div>
      <div>
        <field-validate fieldName="CPF">
          <input
            #cpf
            type="text"
            id="cpf"
            formControlName="cpf"
            mask="000.000.000-00"
            placeholder="000.000.000-00"
            (change)="setFormStorage('cpf')"
            [nextField]="crmv"/>
        </field-validate>
      </div>
      <div class="form-flex">
        <field-validate fieldName="CRMV">
          <input
            #crmv
            numbersOnly
            type="text"
            id="crmvNumber"
            formControlName="crmvNumber"
            placeholder="00000"
            maxlength="5"
            minlength="4"
            (change)="setFormStorage('crmv')"/>
        </field-validate>
        <field-validate fieldName="UF" for="state-crmv" class="uf__field">
          <select
            id="state-crmv"
            placeholder="MG"
            formControlName="state"
            (change)="setFormStorage('estado')">
            <option selected [value]="null">Selecione</option>
            <option *ngFor="let state of listStates" [value]="state.name">{{ state.name }}</option>
          </select>
        </field-validate>
      </div>
      <div class="d-flex">
        <input
          id="acceptedTerms"
          type="checkbox"
          formControlName="acceptedTerms"
          (change)="setFormStorage('aceite_termos')">
        <p class="termos">Aceito os <a [href]="termsPdfLink" target="_blank" rel="noopener noreferrer">termos e as
          políticas de privacidade</a></p>
      </div>
      <span
        *ngIf="registrationForm.controls['acceptedTerms'].touched &&
        registrationForm.controls['acceptedTerms'].hasError('required') && !mobileScreen" class="span-error left">O termo de aceite é obrigatório
      </span>
      <button class="btn-submit" (click)="onSubmit()">Continuar</button>
      <caption *ngIf="registrationForm.controls['acceptedTerms'].touched &&
      registrationForm.controls['acceptedTerms'].hasError('required') && mobileScreen"
               class="error-terms-mobile"
      >
        Para continuar, é preciso aceitar os termos.
      </caption>
    </form>
  </div>

  <section *ngIf="registerSuccessfully">

    <app-confirm-email-message
      [email]="registrationForm.controls['email'].value"
      type="register">
    </app-confirm-email-message>
  </section>
</app-background>
