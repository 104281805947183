<div #selectRef class="infinite-select">
  <div class="relative-container" >
    <label [for]="label">{{ label }}<span *ngIf="required" class="required">*</span></label>
    <div class="relative-container" [style]="'width:' + width">
      <input
        #inputRef
        maxlength="30"
        autocomplete="off"
        [readOnly]="currentValue"
        (click)="handleShowItems()"
        [class.success]="showSuccessBackground && currentValue"
        [class.error]="!optional && options && !options?.length && !disabled && !loading"
        [disabled]="disabled || !options"
        [id]="label"
        [placeholder]="placeholder"
        (input)="onSearch($event)"
        [(ngModel)]="searchValue"
        [style]="actionButton ? 'padding-right: 70px' : 'padding-right: 55px'"
        [style.border-color]="inputFocused ? focusBorderColor : defaultBorderColor"
        (focus)="handleFocus(true)"
        (blur)="handleFocus(false)"
      >
      <app-generic-loading [top]="8" [right]="42" [isLoading]="loading"></app-generic-loading>
      <i
        [style]="actionButton ? 'right: 42px' : 'right: 38px'"
        *ngIf="!loading && searchValue?.length >= 1"
        class="close bi bi-x-circle"
        (click)="clearInput()"></i>
      <button [disabled]="disabledButton" *ngIf="actionButton" type="button" class="action-button" tooltip="{{toolTipText ? toolTipText: null}}" (click)="onAction()">
        <i class="bi bi-plus-lg"></i>
      </button>
      <i *ngIf="!actionButton" class="arrow-down bi bi-caret-down-fill" (click)="handleShowItems()"></i>
    </div>
    <span *ngIf="options != null && !options?.length && !disabled && !loading && !optional" class="span-error">
      {{ errorMessage ? errorMessage : label + ' não encontrado.' }}
    </span>

    <div #dropdownListContainer>
      <ul
        #dropdownList
        *ngIf="dropdownOpen && options?.length"
        [style]="'width:' + width"
        class="select-items"
        [ngStyle]="{'top': calculateTopOffset()}"
        infiniteScroll
        (scrolled)="onScroll()"
        [scrollWindow]="false">
        <li
          *ngFor="let option of options"
          tabindex="0"
          [ngClass]="{'selected' : isEqual(currentValue, option)}"
          class="select-item"
          [class.showSpecieIcon]="showSpecieIcon"
          (click)="select(option)">
          <span *ngIf="optionSubtitle && option[optionSubtitle]">{{ option[optionTitle] }} - {{ option[optionSubtitle] }}</span>
          <span *ngIf="!option[optionSubtitle]">{{ option[optionTitle] }}</span>
          <img *ngIf="showSpecieIcon && option.petEspecie === 'Cat'" src="/assets/icons/cat.png" alt="Gato">
          <img *ngIf="showSpecieIcon && option.petEspecie === 'Dog'" src="/assets/icons/dog.png" alt="Cão">
        </li>
      </ul>
    </div>
  </div>
</div>

