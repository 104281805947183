import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { LoginRequest } from '../../../services/authentication/models/login.request';
import { ValidateCustomEmail } from '../../shared/validators/type-validators';
import { CrossDomainSharingService } from 'src/app/services/cross-domain-sharing.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  loginForm: UntypedFormGroup;
  incorrectData = false;
  urlNavigation: string = environment.urlLp;
  mobileScreen: boolean;
  screenWidth: number;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthenticationService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.createFormLogin();
  }

  createFormLogin(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, ValidateCustomEmail]],
      password: ['', [Validators.required]],
    });
  }

  submit(): void {
    if (this.loginForm.valid) {
      const loginRequest = new LoginRequest();
      loginRequest.password = this.loginForm.get('password').value as string;
      loginRequest.email = this.loginForm.get('email').value as string;
      this.authService.login(loginRequest).subscribe((response) => {
        if (response.accessToken && !response.hasChangePassword){
          this.authService.setLoginItems(response).then(resp => {
            if (resp) {
              this.searchVet();
            }
          });
        }

        if (response.hasChangePassword){
          this.authService.requestChangePassword(loginRequest.email).subscribe(resp => {
            if (resp){
              this.router.navigate(['redefinir-senha/', loginRequest.email]);
            }
          });
        }
      },
        (err) => {
          this.incorrectData = true;
        }
      );
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  searchVet(): void {
    this.authService.loadUserById().subscribe(
      (response) => {
        this.authService.setUser(response);
      },
      (error) => {
        if (error.message === 'User not found') {
          if (this.router.url.indexOf('/cadastro') !== 0) {
            void this.router.navigate(['cadastro']);
          }
        } else {
         void this.router.navigate(['perfil-nao-encontrado']);
        }
      },
    );
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: number): void {
    this.screenWidth = window.innerWidth;
    this.screenWidth > 576 ? this.mobileScreen = false : this.mobileScreen = true;
  }
}
