import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-loading',
  templateUrl: './generic-loading.component.html',
  styleUrls: ['./generic-loading.component.scss'],
})
export class GenericLoadingComponent implements OnInit {
  @Input() isLoading = false;
  @Input() top: number;
  @Input() left: number;
  @Input() right: number;
  @Input() bottom: number;
  constructor() {}

  ngOnInit(): void {}
}
